<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Stat from "./widget";

/**
 * Dashboard component
 */
export default {
   page: {
        title: "Dashboard Home",
        meta: [{ name: "description", content: appConfig.description }]
    },
  components: {
    Layout,
    PageHeader,
    Stat,
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "AfCFTA Caravan",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>
    <div class="row">
      <div class="col-xl-12 content_margin_bottom">
        <Stat />
      </div>
    </div>
  </Layout>
</template>