<script>
import store from "@/state/store";

/**
 * Widget Component
 */
export default {
  data() {
    return {
      copied: false,
      courses: [],
    };
  },
  computed: {
    currentUser() {
      return store.getters["auth/currentUser"];
    },
    entities() {
      return store.getters["auth/entities"];
    },
    // dataProviders(){
    //   return store.getters['entity/dataproviders']
    // },
    //  myDataProviders() {
    //        return store.getters['entity/myDataProviders']
    //    },
  },
  methods: {
    copyText() {
      this.copied = true;
      navigator.clipboard.writeText(this.currentUser.afcfta_number);
    },
  },
  mounted() {
    store
      .dispatch("course/fetchCourses", this.entities[0].entity.id)
      .then((response) => (this.courses = response.data.course));
  },
};
</script>
<style scoped>
.copy {
  border: 1px solid rgb(47, 47, 146);
  outline: none;
  padding-right: 0.5em;
  padding-left: 0.5em;
  background: transparent;
  display: inline;
  margin-left: 1em;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.afcfta_number {
  display: inline;
}
@media screen and (max-width: 400px) {
  .widget_container {
    margin-bottom: 1em;
  }
}
</style>
<template>
  <div class="row widget_container">
    <div class="col-md-4">
      <div class="card">
        <div class="card-body">
          <div class="media py-2">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-4">
                AfCFTA Number
              </p>

              <h4 class="afcfta_number">{{ currentUser.afcfta_number }}</h4>
              <p
                type="button"
                class="copy"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :title="copied ? 'copied!' : 'copy to the clipboard'"
                @click="copyText"
              >
                {{ copied ? "copied!" : "copy" }}
              </p>
            </div>
            <div class="text-primary">
              <i class="far fa-id-badge font-size-24"></i>
            </div>
          </div>
        </div>
        <div class="card-body border-top py-3">
          <div class="text-truncate">
            <span
              v-if="currentUser.verified_afcfta_number"
              class="text-light ml-2  badge rounded-pill bg-success p-2 font-size-11"
              >AfCFTA Number Verified</span
            >
            <span
              v-if="!currentUser.verified_afcfta_number"
              class="text-light ml-2  badge rounded-pill bg-danger p-2 font-size-11"
              >AfCFTA Number Not Verified</span
            >
          </div>
        </div>
      </div>
    </div>
    <!-- user entities -->
    <div class="col-md-4">
      <router-link to="/user/entities">
        <div class="card">
          <div class="card-body">
            <div class="media py-2">
              <div class="media-body overflow-hidden">
                <p class="text-truncate font-size-14 mb-4">
                  Entities
                </p>
                <h4 class="mb-0 ">
                  {{ entities.length ? entities.length : 0 }}
                </h4>
              </div>
              <div class="text-primary">
                <i class="fas fa-users font-size-24"></i>
              </div>
            </div>
          </div>
          <div class="card-body border-top py-3">
            <div class="text-truncate">
              <span
                class="text-muted ml-2   badge badge-soft-success font-size-11 p-2"
              >
                All entities you belongs
              </span>
            </div>
          </div>
        </div>
      </router-link>
    </div>
    <div class="col-md-4">
      <router-link to="/user/courses">
        <div class="card">
          <div class="card-body">
            <div class="media py-2">
              <div class="media-body overflow-hidden">
                <p class="text-truncate font-size-14 mb-4">
                  My Courses
                </p>
                <h4 class="afcfta_number">{{ courses.length }}</h4>
              </div>
              <div class="text-primary">
                <i class="fas fa-graduation-cap font-size-24"></i>
              </div>
            </div>
          </div>

          <div class="card-body border-top py-3">
            <div class="text-truncate">
              <span
                class="text-light ml-2  badge rounded-pill bg-info p-2 font-size-11"
                >All Created Courses</span
              >
            </div>
          </div>
        </div>
      </router-link>
    </div>

    <!-- DATA PROVIDERS -->
    <!-- <div class="col-md-4">
      <router-link to="/user/data_providers">
        <div class="card">
          <div class="card-body">
            <div class="media py-2">
              <div class="media-body overflow-hidden">
                <p class="text-truncate font-size-14 mb-4">
                  Data Providers 
                </p>
                
                  <h4 class="mb-0 ">{{dataProviders && dataProviders.length ? dataProviders.length : 0}}</h4>
              
              </div>
              <div class="text-primary">
                <i class="fas fa-server font-size-24"></i>
              </div>
            </div>
          </div>
          <div class="card-body border-top py-3">
            <div class="text-truncate">
              <span  
                class="text-muted ml-2  badge badge-soft-success font-size-11 p-2">
                All Data Providers
              </span>
            </div>
          </div>
        </div>
      </router-link>
    </div> -->

    <!-- DATA PROVIDERS -->
    <!-- <div class="col-md-4">
      <router-link to="/user/my_data_providers">
        <div class="card">
          <div class="card-body">
            <div class="media py-2">
              <div class="media-body overflow-hidden">
                <p class="text-truncate font-size-14 mb-4">
                  My Data Providers 
                </p>
                <h4 class="mb-0 ">{{myDataProviders && myDataProviders.length ? myDataProviders.length : 0}}</h4>
              </div>
              <div class="text-primary">
                <i class="fas fa-sitemap font-size-24"></i>
              </div>
            </div>
          </div>
          <div class="card-body border-top py-3">
            <div class="text-truncate">
              <span  
                class="text-muted ml-2  badge badge-soft-success font-size-11 p-2">
                All allowd Data Providers
              </span>
            </div>
          </div>
        </div>
      </router-link>
    </div> -->
  </div>
  <!-- end row -->
</template>
